import axios from 'axios'

import store from '../store/store'

const endpoint = (process.env.NODE_ENV === 'development')
    ? 'http://localhost:5001/xmas-wan-2021-d956a/us-central1/' : 'https://us-central1-xmas-wan-2021-d956a.cloudfunctions.net/'


axios.defaults.baseURL = endpoint;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    store.commit('setLoading', true)
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    store.commit('setLoading', false)
    return response;
}, function (error) {
    store.commit('setLoading', false)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

const api = {
    loadInit(callback) {
        axios({
            'url': '/init',
            "method": 'GET',
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        store.commit('setPlayers', response.data.result.players)
                        store.commit('setGames', response.data.result.games)

                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not init data.')
            })
    },
    addPlayer(payload, callback) {

        axios({
            'url': '/addPlayer',
            "method": 'POST',
            'data': payload
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        localStorage.setItem('_id', response.data.result.id)
                        store.commit('setId', response.data.result.id)
                        store.commit('setPlayers', response.data.result.objects)

                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not add player.')
            })
    },

    removePlayer(payload, callback) {

        axios({
            'url': '/removePlayer',
            "method": 'POST',
            'data': payload
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        localStorage.removeItem('_id')
                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not remove player.')
            })
    },

    addGame(payload, callback) {

        axios({
            'url': '/addGame',
            "method": 'POST',
            'data': payload
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        store.commit('setGames', response.data.result.objects)

                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not add game.')
            })
    },

    addParticipation(payload, callback) {

        axios({
            'url': '/addParticipation',
            "method": 'POST',
            'data': payload
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        store.commit('setGames', response.data.result.games)

                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not add participation.')
            })
    },

    removeParticipation(payload, callback) {

        axios({
            'url': '/removeParticipation',
            "method": 'POST',
            'data': payload
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data && response.data.result) {
                        store.commit('setGames', response.data.result.games)

                        if (typeof callback === 'function') {
                            callback()
                        }
                    }
                }
            })
            .catch(function () {
                store.commit('setErrorMessage', 'could not remove participation.')
            })
    },
}

export default api