<template>
  <v-dialog v-model="dialog" persistent max-width="900px">
    <v-tabs v-model="tab" background-color="black accent-4" dark icons-and-text fixed-tabs>
      <v-tab>
        {{ actionVerb }}
        <v-icon>fa-user-plus</v-icon>
      </v-tab>
      <v-tab>
        Bestehende Anmeldung
        <v-icon>fa-sign-in-alt</v-icon>
      </v-tab>

      <v-tab-item>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col class>
                <span class="headline">{{ actionVerb }}</span>
              </v-col>
              <v-col class="justify-end" style="flex-grow: 0;" v-if="hasUser">
                <v-btn elevation="0" color="error" v-on:click="removePlayer">
                  <v-icon left>fa-trash</v-icon>Löschen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col>
                    <v-text-field
                      filled
                      label="Name"
                      v-model="username"
                      required
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12">
                    <h2>Zeitpunkt(e)</h2>Bitte wähle folgend
                    <strong>
                      <em>alle</em>
                    </strong> Tage aus, an denen Du Zeit hättest!
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <v-date-picker
                      :allowed-dates="allowedDates"
                      v-model="dates"
                      first-day-of-week="1"
                      locale="de"
                      picker-date="2021-12-01"
                      min="2021-12-22"
                      max="2021-12-29"
                      multiple
                      no-title
                      color="primary"
                      active-picker="DATE"
                      full-width
                      header-color="primary"
                      v-on:input="resetShowDateError"
                    ></v-date-picker>
                    <time-range-picker
                      v-model="times"
                      full-width
                      hide-details
                      outlined
                      step="30"
                      outerIcon="fa-times"
                      :hideOuterIcon="hideTimeIcon"
                      :hideWholeDayCheckbox="hideTimeChbox"
                      inputLabel="ca. Zeit / ausgewählte Tage"
                      wholeDayLabel="ich kann den ganzen Tag!"
                    />
                  </v-col>
                  <v-col class="col-12 col-md-6">
                    <div v-if="selectedDates.length > 0">
                      <h3>Du hast folgende Daten ausgewählt:</h3>
                      <ul style="font-size: 120%" class="mb-3">
                        <li v-for="date in formattedDates" :key="date">{{ date }}</li>
                      </ul>
                      <h3>Und folgende Zeit gewählt:</h3>
                      <p style="font-size: 120%">
                        Von
                        <strong class="info--text">{{ times.start }}</strong> bis
                        <strong class="info--text">{{ times.end }}</strong>&nbsp;
                        <span class="info--text">Uhr</span>
                      </p>
                    </div>

                    <v-alert
                      type="warning"
                      v-if="selectedDates.length === 0"
                    >Bisher keine Daten ausgewählt!</v-alert>
                    <v-alert type="info" v-if="selectedDates.length === 1">
                      Beim Kalendar kannst Du mehrere Tage auswählen!
                      <br />
                      <br />Wenn möglich, wähle bitte mehrere Tage aus, damit wir einen Tag finden, an dem alle können.
                    </v-alert>
                    <v-alert type="error" v-if="showDateError">Bitte wähle mindestens ein Datum!</v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" text v-on:click.prevent="close">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark elevation="0" v-on:click.prevent="save" x-large color="primary">
              <v-icon left>fa-save</v-icon>Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-title>
            <span class="headline">Bestehende Anmeldung</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <h4>Anmeldung wählen</h4>
              <v-autocomplete
                filled
                v-model="selectedAutocompletePlayer"
                :items="autocompleteEntries"
                class="mt-0"
              ></v-autocomplete>

              <v-alert outlined type="error">
                <p>Du kannst zu jeder bestehenden Anmeldung wechseln. Aber bitte beachte, dass Du dann in diesem Namen Sachen änderst!</p>
                <p class="font-weight-bold">Kurzum: gebe dich nicht als jemand anderes aus!</p>
              </v-alert>

              <v-divider></v-divider>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" text v-on:click.prevent="close">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark elevation="0" v-on:click.prevent="switchToUser" x-large color="primary">
              <v-icon left>fa-save</v-icon>Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

import api from '../api/api'
import { DateTime } from 'luxon'

export default {
  name: 'player-form',
  data: () => ({
    tab: 0,
    id: null,
    valid: true,
    dialog: true,
    selectedAutocompletePlayer: null,
    username: null,
    nameRules: [
      v => !!v || 'Bitte gebe einen Namen an',
    ],
    dates: null,
    times: { start: '00:00', end: '23:59' },
    possibleDates: [
      '2021-12-22',
      '2021-12-23',
      '2021-12-24',
      '2021-12-25',
      '2021-12-26',
      '2021-12-27',
      '2021-12-28',
      '2021-12-29',
    ],
    hideTimeChbox: true,
    hideTimeIcon: true,
    showDateError: false,
  }),
  props: {
    selectedPlayer: {
      type: Object,
      required: false,
    },
    openTab: {
      type: Number,
      required: false,
    }
  },
  mounted() {
    if (typeof this.selectedPlayer === 'object' && this.selectedPlayer && this.selectedPlayer.id) {
      this.id = this.selectedPlayer.id
      this.username = this.selectedPlayer.username
      if (this.selectedPlayer.dates) {
        this.dates = this.selectedPlayer.dates
      }
      if (this.selectedPlayer.times) {
        this.times = this.selectedPlayer.times
      }
    }

    if (Number.isInteger(this.openTab)) {
      this.tab = this.openTab
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    close() {
      this.$emit('resetOpenPlayerFormTab')
      this.$emit('closePlayerDialog')
    },
    switchToUser() {
      if (this.selectedAutocompletePlayer) {
        const playersByName = this.getPlayers.filter((p) => p.username === this.selectedAutocompletePlayer)
        if (playersByName.length === 1) {
          this.setLoading(true)
          localStorage.setItem('_id', playersByName[0].id)
          window.location.reload()
        }
      }
    },
    save() {

      if (this.selectedDates.length === 0) {
        this.showDateError = true
        return
      }

      if (this.$refs.form.validate()) {

        let id = null
        if (this.id) {
          id = this.id
        }

        const payload = {
          id,
          name: this.username,
          dates: this.dates,
          times: this.times
        }
        api.addPlayer(payload, () => {
          this.close()
        })
      }
    },
    allowedDates(date) {
      return this.possibleDates.indexOf(date) !== -1
    },
    resetShowDateError() {
      this.showDateError = false
    },
    removePlayer() {
      if (window.confirm(`Möchtest du Anmeldung "${this.username}" wirklich löschen?`)) {
        api.removePlayer({ id: this.getId }, () => {
          window.location.reload()
        })
      }
    }
  },
  computed: {
    ...mapGetters(['getPlayers', 'getId']),
    actionVerb() {
      return (this.id) ? 'Ändern' : 'Eintragen'
    },
    autocompleteEntries() {
      if (this.getPlayers.length) {
        return this.getPlayers.map((p) => p.username)
      }
      return []
    },
    selectedDates() {
      if (this.dates === null || this.dates.length === 0) {
        return []
      }

      const dates = []
      this.dates.forEach(element => {
        const d = DateTime.fromISO(element).setLocale('de')
        dates.push(d)
      });
      dates.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)
      return dates
    },
    formattedDates() {
      return this.selectedDates.map(item => item.toLocaleString(DateTime.DATE_MED))
    }
  }

}
</script>