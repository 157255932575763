<template>
  <v-calendar
    class="mt-10"
    ref="calendar"
    v-model="value"
    :start="start"
    :end="end"
    locale="de"
    :hide-header="hideHeader"
    :events="calendarEntries"
    :event-color="getEventColor"
  ></v-calendar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      value: null,
      hideHeader: false,
      start: '2021-12-01',
      end: '2021-12-31',
      colors: ['blue', 'pink', 'green', 'orange', 'teal', 'deep-purple', 'cyan', 'amber', 'blue-grey', 'indigo darken-4', 'grey darken-1'],
    }
  },
  computed: {
    ...mapGetters(['getPlayers']),
    calendarEntries() {
      if (this.getPlayers.length === 0) {
        return []
      }

      const entries = []
      let counter = 0
      this.getPlayers.forEach(player => {
        if (player.dates && player.dates.length) {
          const color = this.colors[counter]

          player.dates.forEach(date => {
            const dateObj = new Date(date)

            entries.push({
              name: player.username,
              start: dateObj,
              color: color,
            })
          })

          counter++
        }
      });

      return entries

    }
  },
  methods: {
    getEventColor(event) {
      return event.color
    },
  }
}
</script>