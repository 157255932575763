<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="text-h5 black white--text">Willkommen 🎄🕹</v-card-title>
      <v-card-text>
        <p
          class="pt-5 body-1"
        >Schön, dass Du dich für die XMAS-WAN 2021 interessierst! Damit Du teilnehmen kannst, melde Dich bitte an!</p>
        <p class="pb-10 body-1">
          Wenn Du dich bereits auf einem anderen Gerät angemeldet hast, wähle bitte
          <em>"Bestehende Anmeldung wählen"</em>, ansonsten registriere Dich bitte. Klicke hierzu auf
          <em>"Anmeldung wählen"</em>
        </p>
        <v-row>
          <v-col class="col-md-6 col-12">
            <v-btn large color="secondary" outlined tile block v-on:click="openNewPlayerTab">
              <v-icon left class="mr-5">fa-user-plus</v-icon>Ich will mitmachen!
            </v-btn>
          </v-col>
          <v-col class="col-md-6 col-12">
            <v-btn large color="secondary" outlined tile block v-on:click="openExistingPlayerTab">
              <v-icon left class="mr-5">fa-sign-in-alt</v-icon>Anmeldung wählen
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'no-player-dialog',
  data() {
    return {
      dialog: true
    }
  },
  methods: {
    close() {
      this.$emit('closeNoPlayerDialog')
    },
    openNewPlayerTab() {
      this.$emit('closeNoPlayerDialog')
      this.$emit('openPlayerDialog')
      this.$emit('openPlayerDialogTab', 0)
    },
    openExistingPlayerTab() {
      this.$emit('closeNoPlayerDialog')
      this.$emit('openPlayerDialog')
      this.$emit('openPlayerDialogTab', 1)
    },
  },

}
</script>