<template>
    <v-container>
        <h2 class="h2">{{ getPlayers.length }} Teilnehmer</h2>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Zeitslot</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in getPlayers" :key="i">
                        <td style="vertical-align: top;" class="pt-2">
                            <span class="body-1 font-weight-bold">{{ item.username }}</span>
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <th class="text-left pr-2">Tage</th>
                                    <td>
                                        <span>{{ formattedDates(item) }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left pr-2">Uhrzeit</th>
                                    <td>
                                        von
                                        <span class="info--text">{{ item.times.start }}</span>
                                        bis
                                        <span
                                            class="info--text"
                                        >{{ item.times.end }}</span>
                                        Uhr
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
    name: 'PlayerList',
    computed: {
        ...mapGetters(['getPlayers']),
    },
    methods: {
        formattedDates(player) {
            if (player.dates === null || (player.dates && player.dates.length === 0)) {
                return []
            }

            const dates = []
            player.dates.forEach(element => {
                const d = DateTime.fromISO(element).setLocale('de')
                dates.push(d)
            });
            dates.sort((a, b) => a < b ? -1 : a > b ? 1 : 0)
            return dates.map(item => item.toLocaleString(DateTime.DATE_MED)).join(', ')
        }
    }
}
</script>