<template>
  <v-app>
    <v-snackbar
      color="error"
      v-model="showMessage"
      :timeout="4000"
      centered
      style="z-index:10000;"
      elevation="24"
    >{{ messageText }}</v-snackbar>

    <v-overlay z-index="9999" color="black" opacity=".65" :value="getLoading">
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-overlay>

    <v-app-bar color="none" dark elevate-on-scroll dense app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>🎄 XMAS WAN 2021 🎄</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn color="info" light v-on:click.prevent="openPlayerDialog">
        Hey,
        <strong>{{ username }}</strong>
        <span class="pl-2">📝</span>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app clipped color="grey lighten-5" v-model="drawer">
      <v-list-item
        link
        v-for="(npoint, index) in navigation"
        :key="index"
        v-on:click.prevent="call(npoint.action)"
      >
        <v-list-item-icon>
          <v-icon>{{ npoint.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ npoint.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-slot:append v-if="hasUser">
        <div class="pa-2">
          <v-btn block large color="secondary" v-on:click="logout">
            <v-icon left class="pr-4">fa-sign-out-alt</v-icon>Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <v-alert
          outlined
          type="error"
          class="hand"
          v-if="hasUser === false"
          v-on:click.prevent="openPlayerDialog"
        >
          <p>
            Hallo! Du bist hier noch unbekannt.
            <br />Klicke
            <u>hier</u>, um dich anzumelden.
          </p>
          <p class="ma-0">
            Solltest du dich bereits mit einem anderen Gerät angemeldet haben, kannst du dich auch aus den bestehden Teilnehmern wählen.
            <br />
          </p>
        </v-alert>

        <player-list v-if="view === 'all' || view === 'players'"></player-list>

        <v-container v-if="view === 'all'">
          <v-divider></v-divider>
        </v-container>

        <calendar-view v-if="view === 'calendar' || view === 'players'"></calendar-view>

        <games-list
          v-if="view === 'all' || view === 'games'"
          v-on:openGamesDialog="openGamesDialog"
        ></games-list>
      </v-container>
    </v-main>

    <player-form
      v-if="openedPlayerDialog"
      v-on:closePlayerDialog="closePlayerDialog"
      v-on:resetOpenPlayerFormTab="resetOpenPlayerFormTab"
      :selectedPlayer="selectedPlayer"
      :openTab="openPlayerFormTab"
    ></player-form>
    <games-form
      v-if="openedGamesDialog"
      v-on:closeGamesDialog="closeGamesDialog"
      :selectedGame="selectedGame"
    ></games-form>
    <no-player-dialog
      v-if="hasUser === false && openedPlayerDialog === false"
      v-on:closeNoPlayerDialog="openPlayerDialog"
      v-on:openPlayerDialog="openPlayerDialog"
      v-on:openPlayerDialogTab="openPlayerDialogTab"
    ></no-player-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import api from './api/api'
import PlayerForm from './components/PlayerForm'
import GamesForm from './components/GamesForm'
import PlayerList from './components/PlayerList'
import GamesList from './components/GamesList'
import CalendarView from './components/CalendarView'
import NoPlayerDialog from './components/NoPlayerDialog'

export default {
  name: 'App',
  components: {
    PlayerForm,
    GamesForm,
    PlayerList,
    GamesList,
    CalendarView,
    NoPlayerDialog,
  },
  data: () => ({
    view: 'all',
    drawer: true,
    showMessage: false,
    messageText: null,
    openedPlayerDialog: false,
    openedGamesDialog: false,
    openedNoPlayerDialog: false,
    selectedGame: null,
    selectedPlayer: null,
    openPlayerFormTab: 0,
    navigation: [
      {
        icon: 'fa-home',
        label: 'Home',
        action: 'showHome'
      },
      {
        icon: 'fa-user',
        label: 'Teilnehmer',
        action: 'showPlayers'
      },
      {
        icon: 'fa-calendar',
        label: 'Kalendar',
        action: 'showCalendar'
      }, {
        icon: 'fa-gamepad',
        label: 'Spiele',
        action: 'showGames'
      },
    ]
  }),
  created: function () {
    this.$store.watch(state => state.errorMessage, () => {
      const msg = this.$store.state.errorMessage
      if (msg !== '') {
        this.showMessage = true
        this.messageText = msg
        this.setErrorMessage('')
      }
    })
  },
  beforeMount() {
    if (window.innerWidth <= 1250) {
      this.drawer = false
    }
  },
  mounted() {
    api.loadInit(() => {

    })
  },
  computed: {
    ...mapGetters(['getPlayers', 'getGames', 'getId', 'getLoading']),
    hasUser() {
      return (this.currentUser !== null)
    },
    currentUser() {
      if (!this.getId || !this.getPlayers.length) {
        return null
      }

      const filteredUsers = this.getPlayers.filter(player => player.id === this.getId)
      if (filteredUsers.length === 1) {
        return filteredUsers[0]
      }
      return null
    },
    username() {
      return this.hasUser ? this.currentUser.username : 'Unbekannt'
    }
  },
  methods: {
    ...mapMutations(['setErrorMessage']),
    closePlayerDialog() {
      this.openedPlayerDialog = false
    },
    openPlayerDialog() {
      this.openedPlayerDialog = true
      const playerById = this.getPlayers.filter((pl) => pl.id === this.getId)
      if (playerById.length) {
        this.selectedPlayer = playerById[0]
      } else {
        this.selectedPlayer = null
      }
    },
    closeGamesDialog() {
      this.openedGamesDialog = false
    },
    closeNoPlayerDialog() {
      this.openedNoPlayerDialog = false
    },
    openGamesDialog(game) {
      this.openedGamesDialog = true
      if (typeof game === 'object') {
        this.selectedGame = game
      } else {
        this.selectedGame = null
      }
    },
    call(method) {
      this[method]()
    },
    showHome() {
      this.view = 'all'
    },
    showCalendar() {
      this.view = 'calendar'
    },
    showPlayers() {
      this.view = 'players'
    },
    showGames() {
      this.view = 'games'
    },
    resetOpenPlayerFormTab() {
      this.openPlayerFormTab = null
    },
    openPlayerDialogTab(tab) {
      this.openPlayerFormTab = tab
    },
    logout() {
      localStorage.removeItem('_id')
      window.location.reload()
    }
  }
};
</script>