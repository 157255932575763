function getPlayers(state) {
  return state.players
}

function getGames(state) {
  return state.games
}

function getId(state) {
  return state.id
}

function getErrorMessage(state) {
  return state.errorMessage
}

function getLoading(state) {
  return state.loading
}

export default {
  getPlayers,
  getGames,
  getId,
  getErrorMessage,
  getLoading,
}