<template>
    <v-container>
        <h2 class="h2">
            Spiele:
            <small>Zeige {{ rankedGames.length }} von {{ getGames.length }}</small>

            <v-chip
                class="ma-2 ml-10 d-md-inline-block d-none"
                color="green"
                outlined
            >Grün = optimale Spielerzahl</v-chip>
            <v-chip
                class="ma-2 ml-4 d-md-inline-block d-none"
                color="orange"
                outlined
            >Orange = ausreichend Spieler</v-chip>
            <v-chip
                class="ma-2 ml-4 d-md-inline-block d-none"
                color="red"
                outlined
            >Rot = zu wenige Spieler</v-chip>
        </h2>

        <v-row>
            <v-col class="col-lg-9 col-8">
                <v-row>
                    <v-col class="col-4 col-lg-2 d-none d-md-flex">
                        <v-switch
                            v-model="compactLayout"
                            label="kompakt"
                            color="black"
                            class="mt-1"
                        ></v-switch>
                    </v-col>
                    <v-col class="col-3 d-lg-flex d-none">
                        <v-switch
                            v-model="wtoMe"
                            label="zeige nur Spiele ohne mich"
                            color="black"
                            class="mt-1"
                        ></v-switch>
                    </v-col>
                    <v-col class="col-4 pt-0 d-md-flex d-none col-lg-3">
                        <v-text-field label="Suche" v-model="search" class="mt-0"></v-text-field>
                    </v-col>
                    <v-col class="col-2 pa-0 pr-2 d-lg-flex d-none">
                        <v-autocomplete v-model="selectedSorting" :items="sorting" class="mt-0"></v-autocomplete>
                    </v-col>
                    <v-col class="col-1 pa-0 d-lg-flex d-none">
                        <v-autocomplete v-model="selectedGenreFilter" :items="genres" class="mt-0"></v-autocomplete>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="col-12 col-md-4 col-lg-3">
                <v-btn color="green" block dark large v-on:click.native="openGamesDialog">
                    <v-icon class="pr-4">fa-plus</v-icon>Neues Spiel hinzufügen
                </v-btn>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-list three-line>
            <template v-for="(item) in rankedGames">
                <v-list-item
                    :key="item.title"
                    class="mb-4"
                    :style="{ border: '1px dotted ' + item.playerCountStatus }"
                >
                    <v-list-item-content>
                        <div v-if="compactLayout === true">
                            <v-row>
                                <v-col class="col-md-6">
                                    <v-list-item-title class="text-h5 text-bold" v-html="item.name"></v-list-item-title>
                                </v-col>
                                <v-col class="col-md-3">
                                    <h3>{{ item.player.length }} Spieler</h3>
                                </v-col>
                                <v-col class="col-md-3">
                                    <v-btn
                                        block
                                        color="green darken-2"
                                        dark
                                        v-if="getId && item.participations.indexOf(getId) === -1"
                                        v-on:click.prevent="addParticipation(item.id)"
                                    >
                                        <v-icon left dark style="margin-right: 16px;">fa-gamepad</v-icon>Mitspielen
                                    </v-btn>
                                    <v-btn
                                        block
                                        color="red darken-2"
                                        dark
                                        v-if="getId && item.participations.indexOf(getId) !== -1"
                                        v-on:click.prevent="removeParticipation(item.id)"
                                    >
                                        <v-icon
                                            left
                                            dark
                                            style="margin-right: 16px;"
                                        >fa-sign-out-alt</v-icon>Austragen
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="compactLayout === false">
                            <v-list-item-title class="text-h5 text-bold" v-html="item.name"></v-list-item-title>
                            <v-row>
                                <v-col class="col-6">
                                    <pre
                                        class="body-2 mt-2 font-weight-light font-italic preline mb-4"
                                        v-if="item.description"
                                        v-html="item.description"
                                    ></pre>
                                    <div class="body-2 mt-2">{{ item.playerString }}</div>
                                    <div class="body-2 mt-2">{{ item.genre }}</div>
                                    <div class="body-2 mt-2">
                                        <a
                                            :href="item.storeLink"
                                            target="_blank"
                                        >{{ item.storeName }}-Suche</a>
                                    </div>
                                    <a
                                        href="#"
                                        class="caption blue--text font-italic mt-10 d-inline-block text-decoration-none"
                                        v-on:click.prevent="openGamesDialogWithSelectedGame(item.id)"
                                    >Spiel bearbeiten</a>
                                </v-col>
                                <v-col class="col-4">
                                    <span class="text-h6">
                                        {{ item.player.length }} Spieler
                                        <span
                                            v-if="item.player.length"
                                        >:</span>
                                    </span>
                                    <ol
                                        class="ma-2 body-2"
                                        v-if="item.player && item.player.length"
                                    >
                                        <li v-for="player in item.player" :key="player">{{ player }}</li>
                                    </ol>
                                </v-col>
                                <v-col class="col-2">
                                    <v-btn
                                        block
                                        color="green darken-2"
                                        dark
                                        v-if="getId && item.participations.indexOf(getId) === -1"
                                        v-on:click.prevent="addParticipation(item.id)"
                                    >
                                        <v-icon left dark style="margin-right: 16px;">fa-gamepad</v-icon>Mitspielen
                                    </v-btn>
                                    <v-btn
                                        block
                                        color="red darken-2"
                                        dark
                                        v-if="getId && item.participations.indexOf(getId) !== -1"
                                        v-on:click.prevent="removeParticipation(item.id)"
                                    >
                                        <v-icon
                                            left
                                            dark
                                            style="margin-right: 16px;"
                                        >fa-sign-out-alt</v-icon>Austragen
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

import api from '../api/api'

const defaultGenreFilter = 'Genre zum Filtern wählen'
const defaultSorting = 'Name aufsteigend'
const playerSortingAsc = 'Spieleranzahl aufsteigend'
const playerSortingDesc = 'Spieleranzahl absteigend'

export default {
    name: 'GamesList',
    data() {
        return {
            compactLayout: true,
            wtoMe: false,
            genres: [
                defaultGenreFilter,
                'Shooter',
                'Strategie',
                'Renn-/Sportspiele',
                'Sonstiges'
            ],
            sorting: [
                defaultSorting,
                playerSortingDesc,
                playerSortingAsc
            ],
            selectedGenreFilter: defaultGenreFilter,
            selectedSorting: defaultSorting,
            search: null
        }
    },
    computed: {
        ...mapGetters(['getGames', 'getPlayers', 'getId']),
        rankedGames() {
            let games = []

            this.getGames.forEach(element => {

                const p = (element.participations) ? element.participations.length : 0

                let color = 'grey'
                if (element.optimalPlayer && p === element.optimalPlayer) {
                    color = 'green'
                } else if (element.minimumPlayer && element.minimumPlayer <= p) {
                    color = 'orange'
                } else if (element.minimumPlayer && element.minimumPlayer > p) {
                    color = 'red'
                }

                element.playerCountStatus = color
                element.pCount = p


                element.player = []
                if (element.participations && element.participations.length) {
                    element.participations.forEach((pa) => {
                        const playersById = this.getPlayers.filter((ply) => ply.id === pa)
                        if (playersById.length === 1) {
                            element.player.push(playersById[0].username)
                        }
                    })
                }

                const playerNumberLabels = []
                const playerNumbers = []
                if (element.minimumPlayer) {
                    playerNumberLabels.push('min.')
                    playerNumbers.push(element.minimumPlayer)
                }
                if (element.maxPlayer) {
                    playerNumberLabels.push('max.')
                    playerNumbers.push(element.maxPlayer)
                }
                if (element.optimalPlayer) {
                    playerNumberLabels.push('optimal')
                    playerNumbers.push(element.optimalPlayer)
                }
                element.playerString = 'Spieleranzahl: ' + playerNumberLabels.join('/') + ': ' + playerNumbers.join('/')

                let storeLink = null
                let storeName = null
                if (element.steam) {
                    storeName = 'Steam'
                    storeLink = 'https://store.steampowered.com/search/?term=' + element.name
                } else if (element.origin) {
                    storeName = 'Origin'
                    storeLink = 'https://www.origin.com/deu/de-de/search?searchString=' + element.name
                } else if (element.gog) {
                    storeName = 'GOG'
                    storeLink = 'https://www.google.com/search?q=site%3Agog.com+' + element.name
                } else if (element.epic) {
                    storeName = 'Epic'
                    storeLink = 'https://www.epicgames.com/store/de/browse?pageSize=30&sortBy=relevance&sortDir=DESC&q=' + element.name
                } else if (element.uplay) {
                    storeName = 'Uplay'
                    storeLink = 'https://store.ubi.com/de/game-category#cgid=game-category&prefn1=productPlatformString&prefv1=PC%20Digital%20%28Download%29&prefn2=productTypeRefinementString&prefv2=Spiele'
                }

                element.storeName = storeName
                element.storeLink = storeLink
                games.push(element)
            });

            if (this.selectedSorting === defaultSorting) {
                games.sort(function (a, b) {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                })
            } else if (this.selectedSorting === playerSortingDesc) {
                games.sort(function (a, b) {
                    if (a.pCount < b.pCount) { return 1; }
                    if (a.pCount > b.pCount) { return -1; }
                    return 0;
                })
            } else if (this.selectedSorting === playerSortingAsc) {
                games.sort(function (a, b) {
                    if (a.pCount < b.pCount) { return -1; }
                    if (a.pCount > b.pCount) { return 1; }
                    return 0;
                })
            }

            if (this.wtoMe) {
                const tmpGames = games.filter((g) => {
                    return g.participations.indexOf(this.getId) === -1
                })
                games = tmpGames
            }

            if (this.selectedGenreFilter !== defaultGenreFilter) {
                const tmpGames = games.filter((g) => {
                    return g.genre === this.selectedGenreFilter
                })
                games = tmpGames
            }

            if (this.search !== null && this.search !== '') {
                const regex = new RegExp(this.search, 'gi')
                const tmpGames = games.filter((g) => {
                    return g.name.match(regex)
                })
                games = tmpGames
            }

            return games
        }
    },
    methods: {
        openGamesDialog() {
            this.$emit('openGamesDialog')
        },
        openGamesDialogWithSelectedGame(gameId) {

            const gamesById = this.getGames.filter((el) => el.id === gameId)
            if (gamesById.length) {
                this.$emit('openGamesDialog', gamesById[0])
            }

        },
        addParticipation(gameId) {
            if (this.getId) {
                api.addParticipation({
                    game: gameId,
                    player: this.getId
                })
            }
        },
        removeParticipation(gameId) {
            if (this.getId) {
                api.removeParticipation({
                    game: gameId,
                    player: this.getId
                })
            }
        }
    }
}
</script>