<template>
    <v-dialog v-model="dialog" persistent max-width="660px">
        <v-card>
            <v-card-title>
                <span class="headline">Spiel {{ actionVerb }}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="game-form">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <h3>Name</h3>
                        <p>Bitte gebe hier den Spiel-Namen ein.</p>
                        <v-text-field
                            filled
                            label="Name"
                            v-model="name"
                            required
                            :rules="nameRules"
                        ></v-text-field>
                        <v-textarea
                            filled
                            label="Beschreibung (optional)"
                            rows="2"
                            v-model="description"
                        ></v-textarea>
                        <v-divider></v-divider>
                        <br />
                        <h3>Genre</h3>
                        <v-btn-toggle v-model="genre">
                            <v-btn v-for="genre in genres" :key="genre" color="info">{{ genre }}</v-btn>
                        </v-btn-toggle>
                        <br />
                        <br />
                        <v-divider></v-divider>
                        <br />
                        <h3>Store</h3>
                        <v-btn-toggle v-model="store">
                            <v-btn v-for="store in stores" :key="store" color="warning">{{ store }}</v-btn>
                        </v-btn-toggle>
                        <br />
                        <br />
                        <v-divider></v-divider>
                        <br />
                        <h3>Spielerzahl</h3>
                        <p>Diese Angaben sind optional.</p>
                        <v-range-slider
                            v-model="range"
                            :max="max"
                            :min="min"
                            hide-details
                            class="align-center"
                        >
                            <template v-slot:prepend>
                                <div style="align-self: center; padding-right: 6px; width: 58px">
                                    <small>Min: {{ range[0] }}</small>
                                </div>
                            </template>
                            <template v-slot:append>
                                <div style="align-self: center; padding-left: 6px; width: 58px">
                                    <small>Max: {{ range[1] }}</small>
                                </div>
                            </template>
                        </v-range-slider>

                        <v-row>
                            <v-col class="col-2">Optimal:</v-col>
                            <v-col class="col-10">
                                <v-text-field
                                    v-model="optimal"
                                    single-line
                                    dense
                                    filled
                                    type="number"
                                    label="optimale Spieleranzal"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <br />
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text v-on:click.prevent="close">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn dark elevation="0" v-on:click.prevent="save" x-large color="primary">
                    <v-icon left>fa-save</v-icon>Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import api from '../api/api'

export default {
    name: 'games-form',
    data: () => ({
        id: null,
        valid: true,
        dialog: true,
        genre: null,
        name: null,
        description: null,
        nameRules: [
            v => !!v || 'Bitte gebe einen Namen an',
        ],
        min: 2,
        max: 16,
        range: [2, 16],
        store: null,
        optimal: null,
        genres: [
            'Shooter',
            'Strategie',
            'Renn-/Sportspiel',
            'Sonstiges'
        ],
        stores: [
            'Steam',
            'Origin',
            'Uplay',
            'GOG',
            'Epic',
        ]
    }),
    props: {
        selectedGame: {
            type: Object,
            required: false,
        }
    },
    watch: {
    },
    mounted() {
        if (typeof this.selectedGame === 'object' && this.selectedGame && this.selectedGame.id) {
            this.id = this.selectedGame.id
            this.name = this.selectedGame.name
            this.description = this.selectedGame.description
            this.genre = this.genres.indexOf(this.selectedGame.genre)
            if (this.selectedGame.steam) {
                this.store = 0
            } else if (this.selectedGame.origin) {
                this.store = 1
            } else if (this.selectedGame.uplay) {
                this.store = 2
            } else if (this.selectedGame.gog) {
                this.store = 3
            } else if (this.selectedGame.epic) {
                this.store = 4
            }
            this.optimal = this.selectedGame.optimalPlayer
            if (this.selectedGame.minimumPlayer && this.selectedGame.maxPlayer) {
                this.range = [this.selectedGame.minimumPlayer, this.selectedGame.maxPlayer]
            }
        }
    },
    methods: {
        close() {
            this.$emit('closeGamesDialog')
        },
        save() {
            if (this.$refs.form.validate()) {
                const genre = (this.genres[this.genre]) ? this.genres[this.genre] : null
                const steam = (this.store === 0) * 1
                const origin = (this.store === 1) * 1
                const uplay = (this.store === 2) * 1
                const gog = (this.store === 3) * 1
                const epic = (this.store === 4) * 1

                const minimumPlayer = this.range[0]
                const maxPlayer = this.range[1]
                const optimalPlayer = this.optimal

                let id = null
                if (this.id) {
                    id = this.id
                }

                const payload = {
                    id,
                    name: this.name,
                    description: this.description,
                    steam,
                    genre,
                    origin,
                    epic,
                    uplay,
                    gog,
                    minimumPlayer,
                    maxPlayer,
                    optimalPlayer,
                }
                api.addGame(payload, () => {
                    this.close()
                })
            }
        }
    },
    computed: {
        actionVerb() {
            return (this.id) ? 'ändern' : 'hinzufügen'
        }
    }
}
</script>