import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import VCalendar from 'v-calendar';
import TimeRangePicker from 'vuetify-time-range-picker';

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.component('time-range-picker', TimeRangePicker)

import store from './store/store'

Vue.config.productionTip = false

require('./scss/styles.scss')

if (localStorage.getItem('_id')) {
  store.commit('setId', localStorage.getItem('_id'))
}

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
