function setPlayers(state, players) {
  state.players = players
}
function setGames(state, games) {
  state.games = games
}
function setId(state, id) {
  state.id = id
}
function setErrorMessage(state, errorMessage) {
  state.errorMessage = errorMessage
}
function setLoading(state, loading) {
  state.loading = loading
}

export default {
  setPlayers,
  setGames,
  setId,
  setErrorMessage,
  setLoading,
}